import { Component, ElementRef, OnInit, ViewChild, input, model } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, map, of, startWith } from 'rxjs';
import { AssessmentService } from 'src/app/services/assessment.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrl: './edit-contact.component.scss'
})
export class EditContactComponent implements OnInit {
  edit_contact = model<boolean>();
  refreshUser = input<Subject<void>>();
  user = input<any>();
  selectedForestFlag = false;

  agencies: any;
  regions: any;
  forest_names: any;
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;

  contactForm = this.formBuilder.group({
    first_name: [""],
    last_name: [""],
    email: [""],
    phone: [""],
    agency: [""],
    region: [""],
    forest_name: [""],
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly assessmentService: AssessmentService,
    private readonly userService: UserService,
  ) {
    this.agencies = this.userService.getAgencyList();
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();

    this.filtered_forest_names = this.contactForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnInit(): void {
    this.contactForm.patchValue({
      first_name: this.user().first_name,
      last_name: this.user().last_name,
      email: this.user().email,
      phone: this.user().phone_num,
      agency: this.user().agency_id,
      region: this.user().region,
      forest_name: this.user().forest_name,
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  regionChanged(): void {
    this.contactForm.controls.forest_name.setValue('')
    this.forest_names = this.assessmentService.getForestNameList(this.contactForm.value.region ?? undefined);
    this.filtered_forest_names = this.contactForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  save() {
    let role_list: any[] = [];
    let isActive = this.userService.checkAgencyRegionForest(this.contactForm.value.agency, this.contactForm.value.region, this.contactForm.value.forest_name)
    if (isActive) role_list = [2]

    this.userService.editUserDetails({
      user_id: this.user().user_id,
      first_name: this.contactForm.value.first_name,
      last_name: this.contactForm.value.last_name,
      email: this.contactForm.value.email,
      phone_num: this.contactForm.value.phone,
      agency_id: this.contactForm.value.agency,
      region: this.contactForm.value.region,
      forest_name: this.contactForm.value.forest_name,
      BAER_role_list: role_list
    }).subscribe(() => {
      this.edit_contact.update((x) => {
        return false;
      });
      this.userService.refreshUserProfile();
      window.location.reload();
      this.refreshUser()!.next();
    })
  }

  cancel() {
    this.edit_contact.update((x) => {
      return false;
    });
  }

  clearForest() {
    if (!this.selectedForestFlag){
      this.contactForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }
  forestNameSelected(event: any): void {
    let orgCode = this.assessmentService.getOrgCodeFromForest(event.option.value)
    this.contactForm.patchValue({
      region: orgCode?.substring(0,2),
    })
    this.selectedForestFlag = true
  }
  
}
