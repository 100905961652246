<header class="baer-header">
    <h1 id="table" class="site-page-title"><span class="large-font">Roster</span></h1>
</header>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" *ngIf="user">
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faUsers" style="font-size: 20px;" aria-label="All Users"></fa-icon>&nbsp;
            All Users
        </ng-template>
        <ng-template matTabContent>
            <app-user-roster
            [role]="'ALL_USERS'"
            [currentUser]="user"
            [filterForest]="filterForest"
            [filterRegion]="filterRegion"
            ></app-user-roster>
        </ng-template>
    </mat-tab>
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faUserTie" style="font-size: 20px;" aria-label="Coordination"></fa-icon>&nbsp;
            Coordination
        </ng-template>
        <ng-template matTabContent>
            <app-user-roster
            [role]="'COORDINATORS'"
            [currentUser]="user"
            [filterForest]="filterForest"
            [filterRegion]="filterRegion"
            ></app-user-roster>
        </ng-template>
    </mat-tab>
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <mat-icon aria-label="Line Officers">assignment_ind</mat-icon>&nbsp;
            Line Officers
        </ng-template>
        <ng-template matTabContent>
            <app-user-roster
            [role]="'LINE_OFFICER'"
            [currentUser]="user"
            [filterForest]="filterForest"
            [filterRegion]="filterRegion"
            ></app-user-roster>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="seeUnassigned" aria-hidden="false">
        <ng-template mat-tab-label>
            <mat-icon aria-label="Unassigned Users">person_off</mat-icon>&nbsp;
            Unassigned Users
        </ng-template>
        <ng-template matTabContent>
            <app-user-roster
            [role]="'UNASSIGNED'"
            [currentUser]="user"
            [filterForest]="filterForest"
            [filterRegion]="filterRegion"
            [allow_remove]="false"
            ></app-user-roster>
        </ng-template>
    </mat-tab>
</mat-tab-group>