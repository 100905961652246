import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReportService } from 'src/app/services/report.service';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-watershed-condition',
  templateUrl: './watershed-condition.component.html',
  styleUrls: ['./watershed-condition.component.scss']
})


export class WatershedConditionComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  editor1!: Editor;
  editor2!: Editor;
  editor3!: Editor;
  editor4!: Editor;
  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]

  report = input.required<any>();
  nav = input.required<string>();
  forests = []

  // close = input.required<boolean>();

  form = this.formBuilder.group({
    acresBurned: this.formBuilder.array([]),
  });
  get acresBurned() {
    return this.form.get("acresBurned") as FormArray;
  }

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    public readonly reportService: ReportService,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {
    this.editor1 = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.forests = this.report().assessment.forest_name.split(",")
    this.buildTableForms()
  }

  buildTableForms(): void {
    let state = "Montana"
    let burnData = [
      {
        ownership: "State of "+state,
        acres_burned: 0,
        percent_burned: 0,
        burn_acres_unburned: 0,
        burn_acres_low: 0,
        burn_acres_moderate: 0,
        burn_acres_high: 0,
        report_soil_burn_severity_id: -1
      },
      {
        ownership: "Private",
        acres_burned: 0,
        percent_burned: 0,
        burn_acres_unburned: 0,
        burn_acres_low: 0,
        burn_acres_moderate: 0,
        burn_acres_high: 0,
        report_soil_burn_severity_id: -1
      },
      {
        ownership: "Other Federal",
        acres_burned: 0,
        percent_burned: 0,
        burn_acres_unburned: 0,
        burn_acres_low: 0,
        burn_acres_moderate: 0,
        burn_acres_high: 0,
        report_soil_burn_severity_id: -1
      },
      {
        ownership: "Other General",
        acres_burned: 0,
        percent_burned: 0,
        burn_acres_unburned: 0,
        burn_acres_low: 0,
        burn_acres_moderate: 0,
        burn_acres_high: 0,
        report_soil_burn_severity_id: -1
      },
    ]

    for (let forest of this.forests) {
      burnData.unshift(
        {
          ownership: forest,
          acres_burned: 0,
          percent_burned: 0,
          burn_acres_unburned: 0,
          burn_acres_low: 0,
          burn_acres_moderate: 0,
          burn_acres_high: 0,
          report_soil_burn_severity_id: -1
        }
      )
    }

    for (let item of this.report().soil_burn_severities) {
      let burnDataItem = burnData.find((x: any) => x.ownership == item.ownership)
      if (burnDataItem) {
        burnDataItem.acres_burned = item.acres_burned;
        burnDataItem.percent_burned = item.percent_burned;
        burnDataItem.burn_acres_unburned = item.burn_acres_unburned;
        burnDataItem.burn_acres_low = item.burn_acres_low;
        burnDataItem.burn_acres_moderate = item.burn_acres_moderate;
        burnDataItem.burn_acres_high = item.burn_acres_high;
        burnDataItem.report_soil_burn_severity_id = item.report_soil_burn_severity_id;
      }
    }

    for (let data of burnData) {
      this.acresBurned.push(this.formBuilder.group({
        ownership: data.ownership,
        acres_burned: data.acres_burned,
        percent_burned: data.percent_burned,
        burn_acres_unburned: data.burn_acres_unburned,
        burn_acres_low: data.burn_acres_low,
        burn_acres_moderate: data.burn_acres_moderate,
        burn_acres_high: data.burn_acres_high,
        report_soil_burn_severity_id: data.report_soil_burn_severity_id,
      }))
    }
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.save()
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 4 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 2 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save() {
    this.acresBurned.value.forEach((item: any) => {
      let data = {
        report_id: this.report().report_id,
        ownership: item.ownership,
        acres_burned: +item.acres_burned,
        percent_burned: +item.percent_burned,
        burn_acres_unburned: +item.burn_acres_unburned,
        burn_acres_low: +item.burn_acres_low,
        burn_acres_moderate: +item.burn_acres_moderate,
        burn_acres_high: +item.burn_acres_high,
      }
      if (item.report_soil_burn_severity_id == -1) {
        //record doesn't exist yet
        this.reportService.createBurnSeverity(data).subscribe((res: any) => {
          console.log(res)
        })
      } else {
        this.reportService.editBurnSeverity(item.report_soil_burn_severity_id, data).subscribe((res: any) => {
          console.log(res)
        })
      }
    })
  }   

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }

  sumAcresBurned(level: string) {
    let total = 0
    this.acresBurned.value.forEach((item: any) => {
      total += +item[level]
    })
    return total
  }
}
