import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'confirmation-toast',
  templateUrl: './confirmation-toast.component.html',
})
export class ConfirmationToastComponent {
  constructor(
    private readonly _snackBar: MatSnackBar,) { }

  openSuccessSnackBar(title: string, message?: string,) {
    this._snackBar.openFromComponent(SuccessToastComponent, {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: [],
      data: {
        title: title,
        message: message
      },
      duration: 10000
    });
  }
  openErrorSnackBar(title: string, message?: string,) {
    this._snackBar.openFromComponent(ErrorToastComponent, {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      panelClass: [],
      data: {
        title: title,
        message: message
      },
      duration: 10000
    });
  }
}

@Component({
  selector: 'success-toast',
  templateUrl: './success-toast.component.html',
  styleUrls: [
    './confirmation-toast.component.scss',
    './success-toast.component.scss'
  ],
})
export class SuccessToastComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<SuccessToastComponent>,) { }
}

@Component({
  selector: 'error-toast',
  templateUrl: './error-toast.component.html',
  styleUrls: [
    './confirmation-toast.component.scss',
    './error-toast.component.scss'
  ],
})
export class ErrorToastComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<ErrorToastComponent>,) { }
}