import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataManipulationService {

  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Sends POST request to reset all assessment data
   * 
   * @returns Promise indicating success/failure
   */
  resetAssessmentData(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/test/reset/assessment`, {}, { params: params }
    );
  }

  /**
   * Sends POST request to reset all report data
   * 
   * @returns Promise indicating success/failure
   */
  resetReportData(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/test/reset/report`, {}, { params: params }
    );
  }

  /**
   * Sends POST request to reset all user data
   * 
   * @returns Promise indicating success/failure
   */
  resetUserData(): Observable<any> {
    let params = new HttpParams()
    return this.httpClient.post(
      `${environment.apiUrl}/test/reset/user`, {}, { params: params }
    );
  }
}