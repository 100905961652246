import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { EditAssessmentComponent } from 'src/app/routes/assessments/edit-assessment/edit-assessment.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-team-needs',
  templateUrl: './view-team-needs.component.html',
  styleUrl: './view-team-needs.component.scss'
})
export class ViewTeamNeedsComponent implements OnInit {
  faWarning = faWarning;

  faEdit = faEdit;
  edit_fire = model<boolean>();
  lead: any;
  @Input() fire? : any;
  @Input() updateFireSubject? : Subject<any>;
  @Input() isTeamLead!: boolean;
  @Input() user: any;
  editable = input.required<boolean>();

  constructor(
    public dialog: MatDialog,
    private readonly userService: UserService,
  ) {}

  ngOnInit() {
    if (this.fire?.team_lead_id) {
      this.userService.getUser(this.fire.team_lead_id).subscribe((user: any) => {
        this.lead = user.users[0]
      })
    }
    if (this.fire.supports) {
      let support = this.fire.supports.find((support: any) => {
        return !support.support_completed 
      })
      if (support) {
        this.fire.support = support
      }
    }
  }

  edit() {
    this.dialog.open(EditAssessmentComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
        isTeamLead: this.isTeamLead,
        user: this.user
      },
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }
}
