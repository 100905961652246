<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        @if(state=='EDIT_SKILL') {
        <h1>Edit Team Member</h1>
        }@else {
        <h1>Add Team Member</h1>
        }
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="state=='NEW_OR_EXISTING'">
        <mat-dialog-content>
            <form id="filter_form" class="filter_form" [formGroup]="fireForm">
                <div class="whole-line">
                    <div class="title">Select an option to contiue:</div>
                    <mat-radio-group required formControlName="new_or_existing" class="radio-buttons">
                        <mat-radio-button [value]="'Existing'" color="primary" class="radio-button">Search for an
                            existing team member</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'New'" color="primary">Add a new team
                            member</mat-radio-button>
                    </mat-radio-group>
                </div>
            </form>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <div class="button-row">
                <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
                <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid"
                    (click)="continue()">Continue<i class="material-icons">chevron_right</i></button>
            </div>
        </div>
    </div>
    <div *ngIf="state=='SEARCH_SKILL'">
        <mat-dialog-content>
            <form *ngIf="!is_WO" id="filter_form" class="filter_form" [formGroup]="fireForm">
                <div class="whole-line" style="margin-bottom: -15px">
                    <div class="title">TEAM MEMBER SELECTION</div>
                    <mat-form-field appearance="outline" class="whole-length-field">
                        <mat-label>First or Last Name</mat-label>
                        <input matInput formControlName="user_name" cdkFocusInitial (input)="filterUsers()">
                    </mat-form-field>
                </div>
                <div class="whole-line">
                    <mat-form-field appearance="outline" class="long-field">
                        <mat-label>Skill(s) needed</mat-label>
                        <mat-select formControlName="search_skills" multiple (selectionChange)="filterUsers()">
                            @for (skill of skillList; track skill) {
                            <mat-option [value]="skill.name">{{skill.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="long-field">
                        <mat-label>Skill Level</mat-label>
                        <mat-select formControlName="skill_level" multiple (selectionChange)="filterUsers()">
                            @for (exp of expList; track exp) {
                            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <form *ngIf="is_WO" id="filter_form" class="filter_form" [formGroup]="fireForm">
                <div class="whole-line" style="margin-bottom: -15px">
                    <div class="title">TEAM MEMBER SELECTION</div>
                    <mat-form-field appearance="outline" class="quarter-field">
                        <mat-label>Region</mat-label>
                        <mat-select formControlName="region" (selectionChange)="filterUsers()">
                            @for (region of regions; track region) {
                            <mat-option [value]="region">{{region}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="three-quarter-field">
                        <mat-label>First or Last Name</mat-label>
                        <input matInput formControlName="user_name" cdkFocusInitial (input)="filterUsers()">
                    </mat-form-field>
                </div>
                <div class="whole-line">
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill(s) needed</mat-label>
                        <mat-select formControlName="search_skills" multiple (selectionChange)="filterUsers()">
                            @for (skill of skillList; track skill) {
                            <mat-option [value]="skill.name">{{skill.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Skill Level</mat-label>
                        <mat-select formControlName="skill_level" multiple (selectionChange)="filterUsers()">
                            @for (exp of expList; track exp) {
                            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="third-field">
                        <mat-label>Availability</mat-label>
                        <mat-select formControlName="availability" multiple (selectionChange)="filterUsers()">
                            <mat-option [value]="'region'">
                                Available Regionally
                            </mat-option>
                            <mat-option [value]="'nation'">
                                Available Nationally
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="nis-line" *ngIf="fireForm.value.search_skills?.includes('GIS')">
                <mat-checkbox (change)="filterUsers()" formControlName="NIS_cert">GIS Only: Has NIS Certification</mat-checkbox>
            </div>
            <div class="whole-line" style="margin-bottom: 40px; margin-top: -35px">
                <div class="title">{{ users.data.length }} team member{{ users.data.length == 1 ? "" : "s"}} 
                    <span *ngIf="!is_WO">&nbsp;| Region {{ data.assessment.region }}</span>
                </div>
            </div>
            <div class="table-container">
                <table mat-table [dataSource]="users" class="center" matSort matSortDisableClear aria-hidden="false">
                    <form class="filter_form" [formGroup]="fireForm">
                        <mat-radio-group formControlName="radio">
                            <ng-container matColumnDef="name" sticky>
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

                                <td class="user-cell" mat-cell *matCellDef="let user"
                                    [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                                    <div style="display:flex">
                                        <mat-radio-button [value]="user" color="primary" class="radio-button"
                                            (change)="radioChange($event)"></mat-radio-button>
                                        <div class="user-cell-name">
                                            <div class="user-icon vertical-center">
                                                <p>{{user.first_name[0]}}</p>
                                            </div>
                                            <p class="user-name-2">{{user.first_name + " " + user.last_name}}</p>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                        </mat-radio-group>
                    </form>
                    <ng-container matColumnDef="skills" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef>Skills</th>
                        <td mat-cell *matCellDef="let user"
                            [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                            <mat-chip class="chip"
                                *ngIf="user.skills.length != 0; else skillDef">{{user.skills[0].skill.text}}</mat-chip>
                            <ng-template #skillDef>--</ng-template>
                            <mat-chip class="chip" *ngIf="user.skills.length > 1" style="margin-left: 5px;"
                                [matTooltip]='user.tooltip' matTooltipClass="tooltipClass">
                                +{{user.skills.length - 1}}
                            </mat-chip>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true" class="header-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6">
                            <div class="no-data">
                                <div class="no-data-icon">
                                    <fa-icon [icon]="faUsers" class="no-data-icon"></fa-icon>
                                </div>
                                <div class="no-data-title">No team members found</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bottom-text" *ngIf="!is_WO">
                If you can’t find a team member with a specific skill set, select <b>Request Interregional Support</b>
                from the Team’s page.
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <div class="button-row">
                <button aria-label="Back" class="usa-button usa-button--secondary back-button" (click)="back()">
                    <i class="material-icons back-arrow">chevron_left</i>Back
                </button>
                <div class="gray-help-text">
                    Assign role, permission, and skills for assessment on the next step.
                </div>
                <button aria-label="Update" class="usa-button" [disabled]="selectedUser == null"
                    (click)="continue()">Continue<i class="material-icons">chevron_right</i></button>
            </div>
        </div>
    </div>
    <div *ngIf="state=='ADD_NEW_USER'">

        <mat-dialog-content>
            <div class="support-warning danger-message" *ngIf="userExists">
                <i class="material-icons error-icon danger-icon">error</i>
                <div class="support-text">
                    <span class="support-title">
                        You cannot add a user that already exists in the database.
                    </span>
                    <br>
                    <span class="support-description">
                        Go back and add the user instead.
                    </span>
                </div>
            </div>
            <form class="user_form" [formGroup]="userForm">
                <div class="subtitle"><span class="red">*</span>Indicates required field</div>
                <div class="title-form">USER DETAILS</div>
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="first_name" required cdkFocusInitial>
                    <mat-error>Required Field</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="last_name" required cdkFocusInitial>
                    <mat-error>Required Field</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" required
                        pattern="[a-zA-Z0-9._%+\-]+&#64;[a-z0-9.\-]+\.[a-z]{2,3}$" cdkFocusInitial
                        (focusout)="checkIfUserExists()" (keyup.enter)="checkIfUserExists()">
                    <mat-error *ngIf="userForm.value.email == ''">Required Field</mat-error>
                    <mat-error *ngIf="userForm.value.email != ''">Please enter a valid email</mat-error>
                    <mat-error *ngIf="userExists">Team member already exists</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="half-form">
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone_num" cdkFocusInitial placeholder="(XXX) XXX-XXXX"
                        mask="(000) 000-0000">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Agency</mat-label>
                    <mat-select formControlName="agency_id" required
                        (selectionChange)="updateValidators($event.value, 'agency_id')">
                        @for (agency of agency_list; track agency_list) {
                        <mat-option [value]="agency.value">{{agency.text}}</mat-option>
                        }
                    </mat-select>
                    <mat-error>Required Field</mat-error>
                </mat-form-field>
                <div class="location-info">
                    <div class="title-form"><span class="red">*</span>Location
                        <span class="gray-text" *ngIf="(userForm.controls['agency_id'].getRawValue() ?? 0) == 0">(Home
                            unit)</span>
                        <span class="gray-text"
                            *ngIf="(userForm.controls['agency_id'].getRawValue() ?? 0) != 0">(Closest
                            Forest Service Office)</span>
                    </div>

                    <mat-radio-group required formControlName="location_id" class="radio-buttons"
                        (change)="updateValidators($event.value, 'location_id')">
                        <mat-radio-button [value]="0" color="primary" class="radio-button">Forest
                            Office</mat-radio-button>
                        <mat-radio-button [value]="1" color="primary">Regional Office</mat-radio-button>
                        <mat-radio-button [value]="2" color="primary"
                            *ngIf="userForm.controls['agency_id'].getRawValue() == 0">Washington
                            Office</mat-radio-button>
                    </mat-radio-group>
                    <div
                        *ngIf="userForm.controls['location_id'].getRawValue() == 0 || userForm.controls['location_id'].getRawValue() == 1">
                        <mat-form-field appearance="outline">
                            <mat-label>Region</mat-label>
                            <mat-select formControlName="region" required (selectionChange)="regionChanged()">
                                @for (region of regions; track region) {
                                <mat-option [value]="region">{{region}}</mat-option>
                                }
                            </mat-select>
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                            *ngIf="userForm.controls['location_id'].getRawValue() == 0">
                            <mat-label>Forest Name</mat-label>
                            <input #forest matInput required formControlName="forest_name" [matAutocomplete]="auto"
                                (input)="filter()" (focus)="clearForest()">
                            <mat-autocomplete requireSelection #auto="matAutocomplete"
                                (optionSelected)="forestNameSelected($event)">
                                @for (name of filtered_forest_names | async; track name) {
                                <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                                }
                            </mat-autocomplete>
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <div class="button-row">
                <button aria-label="Back" class="usa-button usa-button--secondary back-button" (click)="back()">
                    <i class="material-icons back-arrow">chevron_left</i>Back
                </button>
                <div class="gray-help-text">
                    Assign role, permission, and skills for assessment on the next step.
                </div>
                <button aria-label="Update" class="usa-button"
                    [disabled]="!userForm.valid || (userForm.value.agency_id && userForm.value.agency_id < 0) || userExists"
                    (click)="continue()">Continue<i class="material-icons">chevron_right</i></button>
            </div>
        </div>
    </div>
    <div *ngIf="state=='SELECT_SKILL' || state=='NEW_USER_SELECT_SKILL' || state=='EDIT_SKILL'">
        <mat-dialog-content>
            <div class="div-label-block" *ngIf="selectedUser">
                <div class="user-cell-name">
                    <div class="user-icon">
                        <p>{{selectedUser.first_name[0]}}</p>
                    </div>
                    <div>
                        <p class="user-name" style="font-weight: bold;">{{selectedUser.first_name + " " +
                            selectedUser.last_name}}</p>
                        <div style="margin-top: 10px;">
                            Skill(s):
                            <mat-chip class="chip"
                                *ngIf="selectedUser.skills.length != 0; else skillDef">{{selectedUser.skills[0].skill.text}}</mat-chip>
                            <ng-template #skillDef>--</ng-template>
                            <mat-chip class="chip" *ngIf="selectedUser.skills.length > 1" style="margin-left: 5px;"
                                [matTooltip]='selectedUser.tooltip' matTooltipClass="tooltipClass">
                                +{{selectedUser.skills.length - 1}}
                            </mat-chip>
                        </div>
                    </div>
                </div>

            </div>
            <form id="filter_form" class="filter_form" [formGroup]="fireForm">
                <div class="whole-line">
                    <div class="title">ASSIGNMENT DETAILS</div>
                    <div style="display: flex;">
                        <mat-form-field appearance="outline">
                            <mat-label>Role</mat-label>
                            <mat-select required formControlName="role" (selectionChange)="roleChange($event.value)">
                                @for (role of roles; track role) {
                                <mat-option [value]="role.value">{{role.text}}</mat-option>
                                }
                            </mat-select>
                            <mat-error>Required Field</mat-error>
                        </mat-form-field>
                        <div>
                            <div class="title"><span class="red">*</span>Permission
                                <mat-icon [matTooltip]="'Limit 2500-8 report editor acess to a few team members. \n\nViewer: can view 2500-8 reports.\nEditor: can edit 2500-8 reports.'" matTooltipClass="tooltipClass"
                                    class="info-blue-field fa-regular fa-circle-info">info_outline</mat-icon>
                            </div>
                            <mat-radio-group required formControlName="editor" class="radio-buttons">
                                <mat-radio-button [value]="false" color="primary" class="radio-button">
                                    Viewer</mat-radio-button>
                                <mat-radio-button [value]="true" color="primary">
                                    Editor</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="whole-line">
                    <div class="title"><span class="red">*</span>Assigned skill(s) for assessment<span
                            class="gray-text">(Select
                            all that apply)</span></div>
                    <div class="whole-line checkBoxGroup">
                        <div *ngFor="let skill of skillList">
                            <mat-checkbox (change)="updateSkill(skill)" [checked]="skill.checked">
                                {{ skill.name }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="right-align" *ngIf="skillList[skillList.length - 1].checked">
                        <div class="title"><span class="red">*</span>Other assigned skill</div>
                        <mat-form-field appearance="outline" class="medium-field">
                            <input matInput formControlName="other_skill" placeholder="Enter other skill"
                                aria-label="Other assigned skill">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-dialog-content>
        <div mat-dialog-actions>
            <div class="button-row">
                @if(state=='EDIT_SKILL') {
                    <button aria-label="Update" class="usa-button usa-button--secondary" mat-dialog-close aria-label="Close">Cancel</button>
                <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid || !formValid"
                    (click)="sumbitRequest()">Update</button>
                } @else {
                <button aria-label="Back" class="usa-button usa-button--secondary back-button" (click)="back()">
                    <i class="material-icons back-arrow">chevron_left</i>Back
                </button>
                <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid || !formValid"
                    (click)="sumbitRequest()">Add To Team Roster</button>
                }
            </div>
        </div>
    </div>
</div>