import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-forest-contacts',
  templateUrl: './view-forest-contacts.component.html',
  styleUrl: './view-forest-contacts.component.scss'
})
export class ViewForestContactsComponent implements OnInit {

  faEdit = faEdit;
  edit_fire = model<boolean>();
  @Input() fire?: any;
  @Input() updateFireSubject?: Subject<any>;
  editable = input.required<boolean>();
  users: any[] = [];

  constructor(
    public dialog: MatDialog,
    private readonly userService: UserService,
  ) { }

  ngOnInit() {

    this.userService.getUserList().subscribe(data => {
      this.users = []
      data.users.forEach((user: any) => {
        // 8-Forest Unit BAER Coordinator
        // 9-Forest BAR Coordinator

        if (!user.baer_roles.find((obj: any) =>
          [8, 9].includes(obj.value))) return

        let forests = this.fire.forest_name.split(",")
        if (!forests.includes(user.forest_name)) return

        this.users.push(user)
        user.showRoles = []
        user.baer_roles.forEach((role: any) => {
          if ([8, 9].includes(role.value))
            user.showRoles.push(role.text)
        })
      })
    })
  }

  edit() {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
      },
    }).afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }
}
