import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'full-phased-modal',
  templateUrl: './full-phased-modal.component.html',
  styleUrl: './full-phased-modal.component.scss'
})
// Full vs. Phased Modal
export class FullPhasedModalComponent {
  
  constructor(
    public dialogRef: MatDialogRef<FullPhasedModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any,
  ) {
    dialogRef.addPanelClass("something")
    dialogRef.updatePosition({
      top: "0px",
      right: "0px"
    })
    dialogRef.updateSize("680px", "100%")
  }
}