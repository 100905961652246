<mat-card>
  <mat-card-header>
    <mat-card-title>TEAM ASSIGNMENTS ({{assignments.data.length}})</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="assignments" class="center" matSort matSortDisableClear>
      <ng-container matColumnDef="baer_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fire/Complex Name</th>
        <td class="user-cell" mat-cell *matCellDef="let assignment">
          <div *ngIf="assignment.baer_name; else complexDef">
            <a [routerLink]="'/fire-overview'" [queryParams]="{assessment_id: assignment.assessment_id}">
              {{assignment.baer_name}}
            </a> 
          </div>
          <ng-template #complexDef>--</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="report_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Report ID</th>
        <td mat-cell *matCellDef="let assignment">{{assignment.report_id}}</td>
      </ng-container>
      <ng-container matColumnDef="forest_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Forest Name</th>
        <td mat-cell *matCellDef="let assignment">{{assignment.forest_name}}</td>
      </ng-container>
      <ng-container matColumnDef="skills">
        <th mat-header-cell *matHeaderCellDef>Skills</th>
        <td mat-cell *matCellDef="let assignment">
          <mat-chip
            *ngIf="assignment.skills.length != 0; else skillDef">{{assignment.skills[0].skill.text}}</mat-chip>
          <ng-template #skillDef>--</ng-template>
          <mat-chip *ngIf="assignment.skills.length > 1" style="margin-left: 5px;" [matTooltip]='assignment.tooltip'
            matTooltipClass="tooltipClass">
            +{{assignment.skills.length - 1}}
          </mat-chip>
        </td>
      </ng-container>
      <ng-container matColumnDef="dates">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tentative Start Date-Close-out Date</th>
        <td mat-cell *matCellDef="let assignment">{{assignment.start_date | date:"MM/dd/YYYY"}} - {{assignment.close_date | date:"MM/dd/YYYY"}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">
          <div class="no-data">
            <div class="no-data-icon">
              <mat-icon class="no-data-icon1">search</mat-icon>
              <mat-icon class="no-data-icon2">whatshot</mat-icon>
            </div>
            <div class="no-data-title">No Team Assignments</div>
            <p>There are no team assignments for this user.</p>
          </div>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
      aria-label="Select page of assignments">
    </mat-paginator>
  </mat-card-content>
</mat-card>