<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">3. Watershed Condition</h2>

    <mat-card [formGroup]="form" style="padding-bottom: 24px;">
        <div class="text-div">
            <b><span class="red">*</span>Burn Severity (acres)</b>
            <br>
            <span class="gray-text">Burn severity acres by ownership</span>
        </div>
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>
        <!-- TABLE Ownership -->
        <div class="table-container">
            <table>
                <tr class="table-header">
                    <th>Category</th>
                    <th class="right">Unbured/Very Low</th>
                    <th class="right">Low</th>
                    <th class="right">Moderate</th>
                    <th class="right">High</th>
                </tr>
                <ng-container formArrayName="acresBurned">
                    <ng-container *ngFor="let data of acresBurned.controls; let i = index">
                        <tr [formGroupName]="i" class="table-row">
                            @if (data.value.ownership == "Other Federal" || data.value.ownership == "Other General") {
                            <td style="width: 70%" class="text-cell" style="display: flex; height: 70px">
                                <div class="vert-center">{{ data.value.ownership }}</div>
                                <div class="vert-center">
                                    <mat-icon matTooltip='Placeholder' matTooltipClass="tooltipClass"
                                        class="info-blue-header fa-regular fa-circle-info">info_outline</mat-icon>
                                </div>
                            </td>
                            } @else {
                            <td style="width: 70%" class="text-cell">
                                {{ data.value.ownership }}
                            </td>
                            }
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input class="right" formControlName="burn_acres_unburned" matInput
                                        aria-label="acresBurned">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input class="right" formControlName="burn_acres_low" matInput
                                        aria-label="acresBurned">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input class="right" formControlName="burn_acres_moderate" matInput
                                        aria-label="acresBurned">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input class="right" formControlName="burn_acres_high" matInput
                                        aria-label="acresBurned">
                                </mat-form-field>
                            </td>
                        </tr>
                    </ng-container>
                    <tr class="sum-row">
                        <td class="text-cell">
                            Total Acres
                        </td>
                        <td class="right">
                            {{ sumAcresBurned('burn_acres_unburned') | number: '1.0-0' }}
                        </td>
                        <td class="right">
                            {{ sumAcresBurned('burn_acres_low') | number: '1.0-0' }}
                        </td>
                        <td class="right">
                            {{ sumAcresBurned('burn_acres_moderate') | number: '1.0-0' }}
                        </td>
                        <td class="right">
                            {{ sumAcresBurned('burn_acres_high') | number: '1.0-0' }}
                        </td>
                    </tr>
                    <tr class="sum-row">
                        <td class="text-cell">
                            SBS % of NFS
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                    </tr>
                    <tr class="sum-row">
                        <td class="text-cell">
                            Ownership % within Fire Perimeter
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                        <td class="right">
                            0%
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Soil Erosion Hazard and Anticipated Response</b>
            <br>
            <span class="gray-text">Please describe the erosion hazard and/or potential of the burned area, including
                the anticipated change of erosion and sedimentation response due to the fire.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor1" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi2'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Debris Flow Potential</b>
            <br>
            <span class="gray-text">Please describe the debris flow hazard and potential of the burned area, including
                any interpretation of the USGS Post-fire Debris Flow Hazards model.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor2" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor2" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi2'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Estimated Vegetative Recovery Period (years)</b>
            <br>
            <span class="gray-text">Please describe the period of time, in years, that is anticipated for the burned
                area to recover vegetation to recover sufficient to reduce runoff and erosion potential (approximately
                60% ground cover). This is a best estimate of natural
                regeneration.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor3" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor3" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi2'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Estimated Hydrologic Response</b>
            <br>
            <span class="gray-text">Provide a brief narrative that describes the predicted hydrologic response due to
                post-fire conditions within the burned
                area. Also describe the predicted storm type and time of year of the first damaging storm.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor4" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor4" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi2'}"></ng-container>


    </mat-card>
</main>

<ng-template #image let-value="test">
    <div class="side-line">
        <div class="text-div">
            <b>Upload image (optional)</b>
            <br>
            <span class="gray-text">Files must be under 64 MB, Files accepted: JPG, PNG</span>
        </div>
        <div class="image-upload">
            Drop file(s) to upload or
            <button class="usa-button usa-button--secondary">Select File(s)</button>
        </div>
        <div class="text-div">
            <b>Images</b>
        </div>
    </div>
</ng-template>
<ng-template #gisWarn>
    <div class="yellow-warning">
        <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
        <div class="support-text">
            <b>Data source:</b> GIS data. Manual edits made below will be overwritten when GIS data is updated.
        </div>
    </div>
</ng-template>