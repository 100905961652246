<main id="main-content" class="main-content">
    <div style="display: flex;">
        <div class="record_count">
            <h2>
                <span class="record_count_text">{{user_count}} Users
                    <span class="location_info" *ngIf="filterForest">
                        &nbsp;&nbsp;&nbsp;<span class="divider">|</span>&nbsp;&nbsp;&nbsp;
                        <span>Region {{currentUser.region}}</span>
                        &nbsp;&nbsp;&nbsp;<span class="divider">|</span>&nbsp;&nbsp;&nbsp;
                        <span>{{currentUser.forest_name}}</span>
                    </span>
                </span>
            </h2>
        </div>
        <div class="button-row" aria-hidden="false">
            <button *ngIf="role=='ALL_USERS' && canEdit" class="usa-button" (click)="addNewUser()">
                <mat-icon>add</mat-icon>Add New User
            </button>
            <button *ngIf="role=='COORDINATORS' && canEdit" class="usa-button medium-length" (click)="addNewUser()">
                <mat-icon>add</mat-icon>Add New Coordinator
            </button>
            <button *ngIf="role=='LINE_OFFICER' && canEdit" class="usa-button medium-length" (click)="addNewUser()">
                <mat-icon>add</mat-icon>Add New Line Officer
            </button>
        </div>
    </div>
    <form id="search_form" class="search-bar" [formGroup]="searchForm">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" (keyup)="search()" placeholder="Search for user...">
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 170px;" *ngIf="!filterForest">
            <mat-label>Region</mat-label>
            <mat-select formControlName="region" (selectionChange)="search();searchRegionChanged($event.value)">
                <mat-option value="All">All regions</mat-option>
                <mat-option value="WO">WO</mat-option>
                @for (region of regions; track region) {
                <mat-option [value]="region">{{region}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 250px;" *ngIf="!filterForest">
            <mat-label>Forest Name</mat-label>
            <input #forest matInput formControlName="forest_name" [matAutocomplete]="auto" (input)="filter()"
                (focus)="clearForest()">
            <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="forestNameSelected()">
                @for (name of filtered_forest_names | async; track name) {
                <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 210px;"
            *ngIf="role != 'COORDINATORS' && role != 'LINE_OFFICER'">
            <mat-label>Agency</mat-label>
            <mat-select formControlName="agency" (selectionChange)="search()">
                <mat-option value=-1>All agencies</mat-option>
                @for (agency of agencies; track agency) {
                <mat-option [value]="agency.value">{{agency.text}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 250px;"
            *ngIf="role != 'COORDINATORS' && role != 'LINE_OFFICER'">
            <mat-label>Skills</mat-label>
            <input #skills matInput formControlName="skill" [matAutocomplete]="auto" (input)="skillFilter()"
                (focus)="clearSkillFilter()" (blur)="search()">
            <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="getSkillName"
                (optionSelected)="skillSelected()">
                @for (name of filtered_skills | async; track name) {
                <mat-option [value]="name">{{name.text}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 350px; width: 100%;"
            *ngIf="role == 'COORDINATORS' || role == 'LINE_OFFICER'">
            <mat-label>Positions</mat-label>
            <input #positions matInput formControlName="position" [matAutocomplete]="auto" (input)="positionFilter()"
                (focus)="clearPositionFilter()" (blur)="search()">
            <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="getPositionName"
                (optionSelected)="positionSelected()">
                @for (position of filtered_positions | async; track position) {
                <mat-option [value]="position">{{position.text}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 250px;"
            *ngIf="role != 'COORDINATORS' && role != 'LINE_OFFICER'">
            <mat-label>Fire Assignment Status</mat-label>
            <mat-select formControlName="available" (selectionChange)="search()">
                <mat-option value='Available'>Available</mat-option>
                <mat-option value='OnAssignment'>On Assignment</mat-option>
                <mat-option value='NotAvailable'>Not Available</mat-option>
            </mat-select>
        </mat-form-field>
        <button class="usa-button usa-button--secondary" (click)="clearSearch()" *ngIf="searchForm.dirty">Clear
            Filters</button>
    </form>
    <table mat-table [dataSource]="users" class="center" matSort matSortDisableClear aria-hidden="false">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="select-header">Select</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div class="usa-checkbox"
                    *ngIf="(filterRegion && (user.region == currentUser.region)) || !filterRegion">
                    <input class="usa-checkbox__input" id="{{user.user_id}}" type="checkbox"
                        [(ngModel)]="user.isSelected" value="{{user.user_id}}" (change)="selectCheck()"
                        aria-label="Toggle Select">
                    <label class="usa-checkbox__label usa-checkbox__label2" for="{{user.user_id}}"> </label>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

            <td class="user-cell" mat-cell *matCellDef="let user"
                [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div>
                    <div class="user-cell-name">
                        <div class="user-icon">
                            <p>{{user.first_name[0]}}</p>
                        </div>
                        <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user_id}"
                            class="user-name">{{user.first_name + " " + user.last_name}}</a>
                    </div>
                    <div class="user-cell-details">
                        <div class="details-number"><mat-icon>phone</mat-icon> {{user.phone_num | phone}}</div>
                        <div class="details-email"><mat-icon>email</mat-icon>{{user.email}}</div>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>WO/Region</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div *ngIf="user.region; else regionDef">{{user.region}}</div>
                <ng-template #regionDef>--</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="forest_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Forest Name</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div *ngIf="user.forest_name; else forestNameDef">{{user.forest_name | forest_filter}}</div>
                <ng-template #forestNameDef>--</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Agency</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div *ngIf="user.agency; else agencyDef">{{user.agency.text}}</div>
                <ng-template #agencyDef>--</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="skills">
            <th mat-header-cell *matHeaderCellDef>Skills</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <mat-chip class="chip"
                    *ngIf="user.skills.length != 0; else skillDef">{{user.skills[0].skill.text}}</mat-chip>
                <ng-template #skillDef>--</ng-template>
                <mat-chip class="chip" *ngIf="user.skills.length > 1" style="margin-left: 5px;"
                    [matTooltip]='user.tooltip' matTooltipClass="tooltipClass">
                    +{{user.skills.length - 1}}
                </mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="positions">
            <th mat-header-cell *matHeaderCellDef>Positions</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <mat-chip *ngIf="user.baer_roles.length != 0; else positionDef">{{user.baer_roles[0].text}}</mat-chip>
                <ng-template #positionDef>--</ng-template>
                <mat-chip *ngIf="user.baer_roles.length > 1" style="margin-left: 5px;" [matTooltip]='user.tooltip2'
                    matTooltipClass="tooltipClass">
                    +{{user.baer_roles.length - 1}}
                </mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="assignment_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fire Assignment Status</th>
            <td mat-cell *matCellDef="let user" [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div class="available" *ngIf="(user.available_personal ?? true) == false; else availableCheck">
                    <mat-icon class="icon_not">block</mat-icon> Not Available
                </div>
                <ng-template #availableCheck>
                    <div class="available" *ngIf="user.filteredAssessment; else availableDef">
                        <mat-icon class="icon_assign">circle</mat-icon> On Assignment <mat-icon class="icon_info"
                            [matTooltip]="user.assessmentTooltip" matTooltipClass="tooltipClass">info_outlined</mat-icon>
                    </div>
                </ng-template>
                <ng-template #availableDef>
                    <div class="available">
                        <mat-icon class="icon_available">check</mat-icon> Available
                    </div>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions" *ngIf="canEdit || canDelete">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let user" class="action-cell"
                [style.background-color]="user.isSelected ? '#FFF5E1' : 'white'">
                <div *ngIf="(filterRegion && (user.region == currentUser.region)) || !filterRegion">
                    <button mat-icon-button aria-label="edit" *ngIf="canEdit" (click)="editUser(user)"
                        style="font-size: 12px;" matTooltip='Edit' matTooltipClass="tooltipClass" class="btn_edit">
                        <fa-icon class="fa-sm" [icon]="faEdit" style="color: #216E1F;"></fa-icon></button>
                    <button mat-icon-button aria-label="remove" *ngIf="allow_remove && canEdit"
                        (click)="removeUser(user)" style="font-size: 12px;" matTooltip='Remove user from roster'
                        matTooltipClass="tooltipClass">
                        <fa-icon class="fa-sm" [icon]="faUserSlash" style="color: #757575;"></fa-icon></button>
                    <button mat-icon-button aria-label="delete" *ngIf="allow_delete && canDelete"
                        (click)="deleteUser(user)" style="font-size: 12px;" matTooltip='Delete'
                        matTooltipClass="tooltipClass" class="btn_delete">
                        <fa-icon class="fa-sm" [icon]="faTrash"></fa-icon></button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">
                <div class="no-data" *ngIf="pageLoaded && user_unfiltered_count != 0">
                    <div class="no-data-icon">
                        <mat-icon class="no-data-icon1">search</mat-icon>
                        <fa-icon [icon]="faUsers" class="no-data-icon2"></fa-icon>
                    </div>
                    <div class="no-data-title">No users found</div>
                    <p>There are no users that match your current filters. Try removing some of them to get better
                        results.</p>
                    <button class="usa-button usa-button--secondary" (click)="clearSearch()">Clear Filters</button>
                </div>
                <div class="no-data" *ngIf="pageLoaded && user_unfiltered_count == 0">
                    <div class="no-data-icon">
                        <mat-icon class="no-data-icon1">search</mat-icon>
                        <fa-icon [icon]="faUsers" class="no-data-icon2"></fa-icon>
                    </div>
                    <div class="no-data-title">No users available</div>
                    <p>There are no users in this roster yet.</p>
                </div>
            </td>
        </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        aria-label="Select page of users">
    </mat-paginator>
</main>