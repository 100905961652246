import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'complexity-modal',
  templateUrl: './complexity-modal.component.html',
  styleUrl: './complexity-modal.component.scss'
})
export class ComplexityModalComponent {
  
  constructor(
    public dialogRef: MatDialogRef<ComplexityModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any,
  ) {
    dialogRef.addPanelClass("something")
    dialogRef.updatePosition({
      top: "0px",
      right: "0px"
    })
    dialogRef.updateSize("680px", "100%")
  }
}