import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent {
  public showTestPages = environment.showTesting;
  showFireTracker: boolean = false;
  showAssessments: boolean = false;
  showReports: boolean = false;
  showRoster: boolean = false;

  constructor(
    private readonly userService: UserService,
  )
  {
    this.setUser();
  }

  setUser() {
    this.userService.getUserProfile().then((user) => {
      if(user?.baer_roles?.length > 0) {
        this.showRoster = true;
        this.showReports = true;
        this.showAssessments = true;
      }
      // 0-Reporting Tool Administrator
      // 3-BAER Regional Coordinator
      // 4-BAER National Coordinator
      // 5-BAER Forest Unit Coordinator
      // 7-Washington Office Director
      // 8-Forest Unit BAER Coordinator
      // 9-Forest BAR Coordinator
      // 10-Forest READ Coordinator
      // 11-Forest Disaster Coordinator
      // 12-RO BAER Coordinator
      // 13-RO BAR Coordinator
      // 14-RO READ Coordinator
      // 15-RO Disaster Coordinator
      // 16-WO Coordination
      // 17-WO Logistics
      const showFireTracker = user?.baer_roles?.find((obj: any) => [0,3,4,5,7,8,9,10,11,12,13,14,15,16,17].includes(obj.value))
      if(showFireTracker) this.showFireTracker = true;
    })
  }
}
