import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    constructor(private readonly httpClient: HttpClient) { }

    getSupportList(filters?: any): Observable<any> {
        let params = new HttpParams()
        let queryParams = ""
        if (filters?.baer_name) {
            queryParams = this.checkForBlank(queryParams);
            queryParams += "baer_name=" + filters?.baer_name
        }
        if (filters?.region) {
            queryParams = this.checkForBlank(queryParams);
            queryParams += "region=" + filters?.region
        }
        if (filters?.support_completed !== undefined) {
            queryParams = this.checkForBlank(queryParams);
            queryParams += "support_completed=" + filters?.support_completed
        }
        if (queryParams != "") {
          queryParams = "?" + queryParams
        }
        return this.httpClient.get(
            `${environment.apiUrl}/support${queryParams}`, { params: params }
        );
    }

    checkForBlank(queryParams: string) {
        if (queryParams != "") queryParams += "&"
        return queryParams
    }
}