import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'login-redirect',
  templateUrl: "./login.component.html",
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {

  private readonly isBrowser: boolean;

  private readonly outerRegularGridColSpan = [1, 7, 4];
  private readonly outerMediumGridColSpan = [1, 10, 1];
  private readonly innerRegularGridColSpan = [2, 12];
  private readonly innerMediumGridColSpan = [3, 11];
  private readonly innerMinimumGridColSpan = [5, 9];
  private readonly innerFullGridColSpan = [0, 14];
  public outerGridColSpan: number[] = [];
  public innerDescriptionGridColSpan: number[] = [];
  public innerButtonGridColSpan: number[] = [];
  public mainTitleFontSize = '';
  public subTitleFontSize = '';
  public buttonWidth = '';
  public isMinimum = false;

  public clicked = false;

  constructor(
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.dialogService.closeSpinner();
    if (this.isBrowser) {
      this.onResize({target: window});
    }
    if (this.authService.checkCredentials()) {
      this.dialogService.openSpinner();
      this.authService.redirect();
    }
  }

  login() {
    this.clicked = true;
    this.dialogService.openSpinner();
    this.authService.logout();
    this.authService.authenticate();
  }

  onResize({target}: any) {
    this.outerGridColSpan.length = 0;
    this.innerDescriptionGridColSpan.length = 0;
    this.innerButtonGridColSpan.length = 0;
    this.isMinimum = false;
    if (target.innerWidth > 1300) {
      this.outerGridColSpan = [...this.outerRegularGridColSpan];
      this.innerDescriptionGridColSpan = this.innerButtonGridColSpan = [...this.innerRegularGridColSpan];
      this.mainTitleFontSize = '32px';
      this.subTitleFontSize = '16px';
      this.buttonWidth = '100%';
    } else if (target.innerWidth > 700) {
      this.outerGridColSpan = [...this.outerMediumGridColSpan];
      this.innerDescriptionGridColSpan = this.innerButtonGridColSpan = [...this.innerMediumGridColSpan];
      this.mainTitleFontSize = '29px';
      this.subTitleFontSize = '14px';
      this.buttonWidth = '100%';
    } else {
      this.outerGridColSpan = [...this.outerMediumGridColSpan];
      this.innerDescriptionGridColSpan = [...this.innerMinimumGridColSpan];
      this.innerButtonGridColSpan = [...this.innerFullGridColSpan];
      this.mainTitleFontSize = '23px';
      this.subTitleFontSize = '11px';
      this.buttonWidth = 'unset';
      this.isMinimum = true;
    }
  }

  ngOnDestroy() {
    this.dialogService.closeSpinner();
  }
}
