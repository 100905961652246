<main id="main-content" class="main-content">
    <div class="subtitle"><span class="red-normal">*</span>Indicates required field</div>
    <h2 class="form-header">2. Burned Area Description</h2>

    <mat-card [formGroup]="form">
        <div class="title">FIRE DETAILS</div>

        <!-- Blue Box -->
        <div class="blue-warning">
            <mat-icon class="blue-warn-icon">info_outline</mat-icon>
            <div class="support-text">
                The fire details have been prefilled with data from the <b>BAER Fire Tracker</b>, where applicable.
            </div>
        </div>

        <div class="whole-line">
            <mat-form-field appearance="outline" class="whole-field">
                <mat-label>Fire or Complex Name</mat-label>
                <input matInput formControlName="fire_complex_name" required aria-label="Fire or Complex Name">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </div>

        <div class="whole-line">
            <mat-form-field appearance="outline" class="third-field">
                <mat-label>Fire Number</mat-label>
                <input matInput formControlName="fire_number" required aria-label="Estimated NFS Acres">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="third-field">
                <mat-label>Fire Incident Job Code</mat-label>
                <input matInput formControlName="fire_number" required aria-label="Estimated NFS Acres">
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </div>
        <div class="whole-line">
            <mat-form-field appearance="outline" class="third-field">
                <mat-label>Fire Start Date</mat-label>
                <input matInput formControlName="fire_start_week" required [matDatepicker]="picker1"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="third-field">
                <mat-label>Estimated Containment Date</mat-label>
                <input matInput formControlName="fire_start_week" required [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="third-field">
                <mat-label>True Containment Date</mat-label>
                <input matInput formControlName="fire_start_week" required [matDatepicker]="picker3"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error>Required Field</mat-error>
            </mat-form-field>
        </div>

        <div class="bar">&nbsp;</div>
        <div class="title">FIRE LOCATION <span class="gis-source-text"><b>Data source:</b> GIS Database</span></div>
        <div *ngIf="report().assessment" style="display: flex; flex-wrap:wrap">
            <div class="data-div">
                <p class="data-title">Region</p>
                <p class="data-info">{{report().assessment.region ?? "--"}}</p>
            </div>
            <div class="data-div">
                <p class="data-title">State of Fire Origin</p>
                <p class="data-info">{{report().assessment.state ?? "--"}}</p>
            </div>
            <div class="data-div">
                <p class="data-title">Forest(s)</p>
                <p class="data-info">{{report().assessment.forest_name ?? "--"}}</p>
            </div>
            <div class="data-div">
                <p class="data-title">State(s) Affected</p>
                <p class="data-info">{{report().assessment.states ?? "--"}}</p>
            </div>
            <div class="data-div">
                <p class="data-title">Counties</p>
                <p class="data-info">{{report().assessment.counties ?? "--"}}</p>
            </div>
            <div class="data-div">
                <p class="data-title">Ranger District(s)</p>
                <p class="data-info">{{report().assessment.districts ?? "--"}}</p>
            </div>
        </div>

        <div class="bar">&nbsp;</div>
        <div class="title"><span class="red">*</span>Subwatershed Numbers (All NFS)</div>
        <div class="text-div">
            <b>Acres Burned by Subwatershed</b>
            <br>
            <span class="gray-text">Based on all Soil Burn Severity categories: unburned/very low, low, moderate,
                high.</span>
        </div>
        <!-- Yellow Banner -->
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>


        <!-- Table HU12# -->
        <div class="table-container">
            <table>
                <tr class="table-header">
                    <th style="display: flex">
                        <div class="vert-center">HU#12</div>
                        <div class="vert-center">
                            <mat-icon matTooltip='12-digit Hydrologic number' matTooltipClass="tooltipClass"
                                class="info-blue-header fa-regular fa-circle-info">info_outline</mat-icon>
                        </div>
                    </th>
                    <th>Subwatershed Name</th>
                    <th>Total Acres</th>
                    <th>Acres Burned</th>
                    <th>% Subwatershed Burned</th>
                    <th></th>
                </tr>
                <ng-container formArrayName="subwatershed">
                    <ng-container *ngFor="let data of subwatershed.controls; let i = index">
                        <tr [formGroupName]="i" class="table-row">
                            <td>
                                <mat-form-field appearance="outline" class="example">
                                    <input formControlName="HU12" matInput aria-label="HU12">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example">
                                    <input formControlName="name" matInput aria-label="name">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input formControlName="totalAcres" matInput aria-label="totalAcres"
                                        thousandSeparator="," mask="separator" decimalMarker=".">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input formControlName="acresBurned" matInput aria-label="acresBurned"
                                        thousandSeparator="," mask="separator" decimalMarker=".">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="example small-field">
                                    <input formControlName="percentBurned" matInput aria-label="percentBurned"
                                        mask="00%">
                                </mat-form-field>
                            </td>
                            <td class="close-td">
                                <div class="delete-div" matTooltip='Remove' matTooltipClass="tooltipClass">
                                    <fa-icon [icon]="faCircleXmark" class="fa-regular fa-circle-mark delete-row-icon"
                                        aria-label="close"></fa-icon>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </div>

        <div class="whole-line" style="padding-left: 17px;">
            <button class="usa-button usa-button--secondary"><mat-icon>add</mat-icon>Add row</button>
        </div>
        <div class="bar">&nbsp;</div>


        <div class="text-div">
            <b><span class="red">*</span>Total Acres Burned</b>
            <br>
            <span class="gray-text">Acres burned by Ownership</span>
        </div>
        <!-- Yellow Banner -->
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>

        <!-- TABLE Ownership -->
        <table>
            <tr class="table-header">
                <th>Ownership</th>
                <th>Acres Burned</th>
                <th>% Acres Burned</th>
            </tr>
            <ng-container formArrayName="acresBurned">
                <ng-container *ngFor="let data of acresBurned.controls; let i = index">
                    <tr [formGroupName]="i" class="table-row">
                        @if (data.value.name == "Other Federal" || data.value.name == "Other General") {
                        <td style="width: 70%" class="text-cell" style="display: flex; height: 70px">
                            <div class="vert-center">{{ data.value.name }}</div>
                            <div class="vert-center">
                                <mat-icon matTooltip='Placeholder' matTooltipClass="tooltipClass"
                                    class="info-blue-header fa-regular fa-circle-info">info_outline</mat-icon>
                            </div>
                        </td>
                        } @else {
                        <td style="width: 70%" class="text-cell">
                            {{ data.value.name }}
                        </td>
                        }
                        <td>
                            <mat-form-field appearance="outline" class="example">
                                <input formControlName="acresBurned" matInput aria-label="acresBurned"
                                    thousandSeparator="," mask="separator" decimalMarker=".">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" class="example">
                                <input formControlName="percentBurned" matInput aria-label="percentBurned" mask="00"
                                    suffix="%">
                            </mat-form-field>
                        </td>
                    </tr>
                </ng-container>
                <tr class="sum-row">
                    <td class="text-cell">
                        Total Acres Burned
                    </td>
                    <td class="right">
                        {{ sumAcresBurned() | number: '' }}
                    </td>
                    <td class="right">
                        0%
                    </td>
                </tr>
            </ng-container>
        </table>
        <div class="bar">&nbsp;</div>
        <div class="text-div">
            <b><span class="red">*</span>Vegetation Types</b>
            <br>
            <span class="gray-text">Please describe the major plant communities or vegetation types in the burned area,
                include appropriate fire recurrence information if applicable.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor1" placeholder="Enter Description"></ngx-editor>
        </div>
        <!-- Image upload -->
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Dominant Soils</b>
            <br>
            <span class="gray-text">Please describe the soil types and topography that best characterizes the burned
                area. Use soil name that best reflect soil characteristics and level of available information.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor2" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor2" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi2'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Geologic Types</b>
            <br>
            <span class="gray-text">Please describe the geologic types and geomorphology that best characterizes the
                burned area.</span>
        </div>
        <!-- Rich Text input -->
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor3" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor3" placeholder="Enter Description"></ngx-editor>
        </div>
        <ng-container *ngTemplateOutlet="image; context: { test: 'Hi3'}"></ng-container>

        <div class="text-div">
            <b><span class="red">*</span>Miles of Stream Channels by Type</b>
            <br>
            <span class="gray-text">The table below shows National NHD data. A value of ‘0’ in ‘Ephemeral’ may mean data
                has not been mapped.</span>
        </div>
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>
        <!-- Table Steam Type -->
        <table>
            <tr class="table-header">
                <th>Stream Type</th>
                <th class="right" *ngFor="let forest of forests">
                    {{ forest }}
                </th>
                <th class="right last-column">Total</th>
            </tr>
            <tr *ngFor="let stream of streamTypes" class="table-row">
                <td class="text-cell" style="width: 70%">
                    {{ stream }}
                </td>
                <ng-container formArrayName="streams">
                    <ng-container *ngFor="let data of streams.controls; let i = index">
                        <td [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example small-field">
                                <input class="right" [formControlName]="stream" matInput aria-label="stream miles"
                                    thousandSeparator="," mask="separator" decimalMarker=".">
                            </mat-form-field>
                        </td>
                    </ng-container>
                </ng-container>
                <td class="right last-column">
                    <b>{{ sumStream(stream) | number: '' }}</b>
                </td>
            </tr>
        </table>

        <div class="text-div">
            <b><span class="red">*</span>NFS Roads</b>
            <br>
            <span class="gray-text">National Forest Service Road Miles by Operational Maintenance level.</span>
        </div>
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>
        <!-- Table Operational Maintenance Level -->
        <table>
            <tr class="table-header">
                <th id="optionalMaitLvl">Operational Maintenance Level</th>
                <th id="forest" *ngFor="let forest of forests" class="right">
                    {{ forest }}
                </th>
            </tr>
            <tr *ngFor="let level of maintenanceLevels" class="table-row">
                <td class="text-cell" style="width: 70%">
                    {{ level }}
                </td>
                <ng-container formArrayName="maintenance">
                    <ng-container *ngFor="let data of maintenance.controls; let i = index">
                        <td [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example small-field">
                                <input class="right" [formControlName]="level" matInput aria-label="stream miles"
                                    thousandSeparator="," mask="separator" decimalMarker="." decimalMarker=".">
                            </mat-form-field>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr class="sum-row">
                <td class="text-cell">
                    Total Road Miles
                </td>
                <th id="totalRoadMiles" *ngFor="let forest of forests; let i = index" class="right text-cell">
                    {{ sumRoadMiles(i) | number: '' }}
                </th>
            </tr>
        </table>

        <div class="text-div short-padding">
            <b><span class="red">*</span>Other Roads</b>
        </div>

        <!-- Table Other Roads -->
        <table class="small-table" role="presentation">
            <tr class="table-row">
                <td class="text-cell" style="width: 70%">
                    Other Roads
                </td>
                <ng-container formArrayName="maintenance">
                    <ng-container *ngFor="let data of maintenance.controls; let i = index">
                        <td [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example small-field">
                                <input class="right" formControlName="other" matInput aria-label="stream miles"
                                    thousandSeparator="," mask="separator" decimalMarker=".">
                            </mat-form-field>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </table>
        <div class="side-line">
            <div class="text-div">
                <b>Additional Details</b>
                <!-- Fancy Text -->
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor4" [toolbar]="toolbar"></ngx-editor-menu>
                    <ngx-editor [editor]="editor4" placeholder="Enter Description"></ngx-editor>
                </div>
            </div>
        </div>

        <div class="text-div">
            <b><span class="red">*</span>Transportation System</b>
            <br>
            <span class="gray-text">Forest Service Terrestrial Trail Miles by Type</span>
        </div>
        <ng-container *ngTemplateOutlet="gisWarn"></ng-container>
        <!-- Table Trail Type -->
        <table>
            <tr class="table-header">
                <th>Trail Type</th>
                <th class="right" *ngFor="let forest of forests">
                    {{ forest }}
                </th>
                <th class="right last-column">Total</th>
            </tr>
            <tr *ngFor="let trail of trailTypes" class="table-row">
                <td class="text-cell" style="width: 70%">
                    {{ trail }}
                </td>
                <ng-container formArrayName="trails">
                    <ng-container *ngFor="let data of trails.controls; let i = index">
                        <td [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example small-field">
                                <input class="right" [formControlName]="trail" matInput aria-label="trail miles"
                                    thousandSeparator="," mask="separator" decimalMarker=".">
                            </mat-form-field>
                        </td>
                    </ng-container>
                </ng-container>
                <td class="right last-column">
                    <b>{{ sumTrail(trail) | number: '' }}</b>
                </td>
            </tr>
        </table>
    </mat-card>
</main>

<ng-template #image let-value="test">
    <div class="side-line">
        <div class="text-div">
            <b>Upload image (optional)</b>
            <br>
            <span class="gray-text">Files must be under 64 MB, Files accepted: JPG, PNG</span>
        </div>
        <div class="image-upload">
            Drop file(s) to upload or
            <button class="usa-button usa-button--secondary">Select File(s)</button>
        </div>
        <div class="text-div">
            <b>Images</b>
        </div>
    </div>
</ng-template>
<ng-template #gisWarn>
    <div class="yellow-warning">
        <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
        <div class="support-text">
            <b>Data source:</b> GIS data. Manual edits made below will be overwritten when GIS data is updated.
        </div>
    </div>
</ng-template>