import { Component, OnChanges, OnInit, input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-burned-area',
  templateUrl: './burned-area.component.html',
  styleUrls: ['./burned-area.component.scss']
})


export class BurnedAreaComponent implements OnChanges, OnInit {

  faWarning = faWarning;
  faCircleXmark = faCircleXmark as IconProp;

  im = new Inputmask({
    groupSeparator: ',',
    prefix: '$',
    allowMinus: false
  });

  editor1!: Editor;
  editor2!: Editor;
  editor3!: Editor;
  editor4!: Editor;
  toolbar: Toolbar = [
    ["bold", "italic", "underline", "strike", "background_color", "bullet_list", "ordered_list"]
  ]
  html =  "<p>Hello</p>"

  report = input.required<any>();
  nav = input.required<string>();
  forests = []

  // close = input.required<boolean>();

  streamTypes = [
    "Perennial",
    "Intermittent",
    "Ephemeral",
    "Canal/Ditch/Pipeline",
  ]

  maintenanceLevels = [
    "1 - Basic Custodial Care (Closed)",
    "2 - High Clearance Vehicles",
    "3 - Suitable For Passenger Cars",
    "4 - Moderate Degree of User Comfort",
    "5 - High Degree of User Comfort",
    "Unknown or Decommissioned",
  ]

  trailTypes = [
    "Motorized",
    "Non-motorized",
  ]

  form = this.formBuilder.group({
    fire_complex_name: [""],
    fire_number: [""],
    fire_start_week: [null] as unknown as Date[],
    subwatershed: this.formBuilder.array([]),
    acresBurned: this.formBuilder.array([]),
    streams: this.formBuilder.array([]),
    maintenance: this.formBuilder.array([]),
    trails: this.formBuilder.array([]),
  });
  get subwatershed() {
    return this.form.get("subwatershed") as FormArray;
  }
  get acresBurned() {
    return this.form.get("acresBurned") as FormArray;
  }
  get streams() {
    return this.form.get("streams") as FormArray;
  }
  get maintenance() {
    return this.form.get("maintenance") as FormArray;
  }
  get trails() {
    return this.form.get("trails") as FormArray;
  }

  constructor(
    public dialog: MatDialog,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    this.titleService.setTitle("BAER - Report Type")
  }

  ngOnInit(): void {
    this.editor1 = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.forests = this.report().assessment.forest_name.split(",")
    this.buildTableForms()
  }

  buildTableForms(): void {
    let sampleData = [
      {
        HU12: 101020201,
        name: "Gap Creek-Verde River",
        totalAcres: 39500,
        acresBurned: 14578,
        percentBurned: 23,
      },
      {
        HU12: 101020201,
        name: "Gap Creek-Verde River",
        totalAcres: 39500,
        acresBurned: 14578,
        percentBurned: 23,
      },
      {
        HU12: 101020201,
        name: "Gap Creek-Verde River",
        totalAcres: 39500,
        acresBurned: 14578,
        percentBurned: 23,
      },
    ]
    for (let data of sampleData) {
      this.subwatershed.push(this.formBuilder.group({
        HU12: data.HU12,
        name: data.name,
        totalAcres: data.totalAcres,
        acresBurned: data.acresBurned,
        percentBurned: data.percentBurned,
      }))
    }
    let state = "Montana"
    let sampleData2 = [
      {
        name: "State of "+state,
        acresBurned: 123,
        percentBurned: 50,
      },
      {
        name: "Private",
        acresBurned: 14578,
        percentBurned: 23,
      },
      {
        name: "Other Federal",
        acresBurned: 14578,
        percentBurned: 23,
      },
      {
        name: "Other General",
        acresBurned: 14578,
        percentBurned: 23,
      },
    ]
    for (let forest of this.forests) {
      this.acresBurned.push(this.formBuilder.group({
        name: forest,
        acresBurned: 123,
        percentBurned: 50,
      }))
      let questions1: any = {}
      for (let stream of this.streamTypes) {
        questions1[stream] = [0];
      }
      this.streams.push(this.formBuilder.group(questions1))
      let questions2: any = {"other": 0}
      for (let level of this.maintenanceLevels) {
        questions2[level] = [0];
      }
      this.maintenance.push(this.formBuilder.group(questions2))
      let questions3: any = {}
      for (let trail of this.trailTypes) {
        questions3[trail] = [0];
      }
      this.trails.push(this.formBuilder.group(questions3))
    }
    for (let data of sampleData2) {
      this.acresBurned.push(this.formBuilder.group({
        name: data.name,
        acresBurned: data.acresBurned,
        percentBurned: data.percentBurned,
      }))
    }
  }

  ngOnChanges(): void {
    if (this.nav() != '') this.navigate();
  }

  navigate() {
    //TODO - save and close triggered. save report progress and return to overview page
    switch (this.nav()) {
      case 'forward': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 3 } });
        break;
      }
      case 'back': {
        this.router.navigate(['/2500-8-report'], { queryParams: { report_id: this.report().report_id, report_page_input: 1 } });
        break;
      }
      case 'close': {
        this.router.navigate(['fire-overview'], { queryParams: { assessment_id: this.report().assessment_id } });
        break;
      }
    }
  }

  save_close() {
    //TODO - save and close triggered. save report progress and return to overview page
    
    this.router.navigate(['fire-overview'], { queryParams: {assessment_id: this.report().assessment_id } });
  }

  sumRoadMiles(i: number) {
    let values = (this.form.value.maintenance as any)[i]
    values["other"] = 0
    let total = 0
    for (let key in values) {
      total += +(values[key] ?? 0)
    }
    return total
  }

  sumStream(stream: string) {
    let total = 0
    this.form.value.streams?.forEach((item: any) => {
      total += +(item[stream] ?? 0)
    })
    return total
  }

  sumTrail(trail: string) {
    let total = 0
    this.form.value.trails?.forEach((item: any) => {
      total += +(item[trail] ?? 0)
    })
    return total
  }

  sumAcresBurned() {
    let total = 0
    this.form.value.acresBurned?.forEach((item: any) => {
      total += +(item.acresBurned ?? 0)
    })
    return total
  }
}
