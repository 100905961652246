import { Component, SimpleChanges, input, model } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-skills',
  templateUrl: './view-skills.component.html',
  styleUrl: './view-skills.component.scss'
})
export class ViewSkillsComponent {
  faEdit = faEdit;
  edit_skills = model<boolean>();
  user = input<any>();
  editable = input.required<boolean>();
  skills_list: any;
  skillIdMap = new Map<number, any>(); // Map skill_ids to skill objects which are shown

  constructor(
    private readonly userService: UserService,
  ) {
    this.userService.getSkillList().subscribe((skills) => {
      this.skills_list = skills;
      this.skills_list.forEach((item: any) => {
        this.skillIdMap.set(item.value, item)
      })
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.skillIdMap.forEach((skill: any) => {
      skill.experience = '--';
    });
    if (this.skills_list && this.user()) {
      this.user().skills.forEach((skill: any) => {
        // Setting the experience on object in the skills list to display current user
        this.skillIdMap.get(skill.skill_id).experience = skill.experience.text.replace(' assignments', '');
      });
    }

    this.skills_list.forEach((skill: any) => {
      skill.text = skill.text.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase());
    })
  }

  edit() {
    this.edit_skills.update((x) => {
      return true;
    });
  }
}
