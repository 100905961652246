import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoggedIn: boolean = false;
  private readonly isBrowser: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkCredentials();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe({next: events => this.isLoggedIn = this.authService.checkCredentials()});
  }

  scrollToTop(event: any) {
    event.preventDefault();
    if (this.isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
