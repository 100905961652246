import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser } from "@angular/common";
import { faWarning } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrl: './edit-assessment.component.scss'
})
export class EditAssessmentComponent implements OnInit {
  faWarning = faWarning;

  private readonly isBrowser: boolean;

  currentUser: any;
  private readonly fireRecord: any = this.data.fireRecord;

  displayedColumns!: string[];

  team_needs: any[];
  userList: any[] = [];
  leadList: any[] = [];
  regions: any[];
  is_WO = false;

  fireForm = this.formBuilder.group({
    notes: [""],
    teamLeads: [{ value: null, disabled: true }],
    team_needs_id: [-1],
    close_out_date: [null] as unknown as Date[],
    tentative_start_week: [null] as unknown as Date[],
    trainees_needed: [false],
    region: [""],
  });

  constructor(
    public dialogRef: MatDialogRef<EditAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    public readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.titleService.setTitle("BAER - Edit Team Needs")
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList().slice();
    this.regions.unshift("--")

    this.team_needs = this.assessmentService.getBAERTeamNeeds();
  }

  ngOnInit(): void {
    if (this.fireRecord.supports?.length > 0) {
      this.fireRecord.supports.forEach((support: any) => {
        if (!support.support_completed) this.fireRecord.support = true // Show banner
      })
    }
    if (this.data.fireRecord.assessment_status_id > 2)
      this.fireForm.get('teamLeads')?.disable();
    else
      this.fireForm.get('teamLeads')?.enable();
    this.fireForm.patchValue({
      teamLeads: this.fireRecord.team_lead_id,
      notes: this.fireRecord.notes,
      team_needs_id: this.fireRecord.team_needs_id,
      close_out_date: this.fireRecord.close_out_date,
      tentative_start_week: this.fireRecord.tentative_start_week,
      trainees_needed: this.fireRecord.trainees_needed,
      region: this.fireRecord.team_lead?.region,
    })
    this.findAvalibleLeads(this.data.isTeamLead)
  }

  getLeadName() {
    let user = this.leadList.find((x: any) => x.user_id == this.fireForm.getRawValue().teamLeads);
    if (user)
      return user.first_name + " " + user.last_name;
    else
      return null;
  }

  editAssessment(): void {
    if (typeof this.fireForm.value.close_out_date === 'string') {
      this.fireForm.value.close_out_date = new Date(new Date(this.fireForm.value.close_out_date).getTime() + 60 * 1000 * new Date().getTimezoneOffset())
    }
    if (typeof this.fireForm.value.tentative_start_week === 'string') {
      this.fireForm.value.tentative_start_week = new Date(new Date(this.fireForm.value.tentative_start_week).getTime() + 60 * 1000 * new Date().getTimezoneOffset());
    }
    let close_out_date: Date = this.fireForm.value.close_out_date as unknown as Date
    let tentative_start_week: Date = this.fireForm.value.tentative_start_week as unknown as Date
    let close_out_date_string = undefined;
    let tentative_start_week_string = undefined;
    if (close_out_date) {
      close_out_date_string = close_out_date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    if (tentative_start_week) {
      tentative_start_week_string = tentative_start_week.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    let supports = undefined
    if (this.fireForm.getRawValue().teamLeads) {
      // For assessments, we can assume all support types are "Team Lead", and are completed once a team lead is assigned
      supports = this.fireRecord.supports.map((support: any) => {
        support.support_completed = true;
        return support;
      })
    }

    this.assessmentService.editAssessment({
      assessment_id: this.fireRecord.assessment_id,
      notes: this.fireForm.value.notes as string | undefined,
      team_needs_id: this.fireForm.value.team_needs_id as number | undefined,
      close_out_date: close_out_date_string,
      tentative_start_week: tentative_start_week_string,
      trainees_needed: this.fireForm.value.trainees_needed as boolean | undefined,
      team_lead_id: this.fireForm.getRawValue().teamLeads! as number | undefined,
      supports: supports
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.fireRecord.baer_name} assessment has been updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error updating ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
        this.dialogRef.close();
      },
    })
  }

  findAvalibleLeads(isTeamLead: boolean) {
    // 0-BAER Reporting Tool Administrator
    // 4-BAER National Coordinator
    // 7-Washington Office Director
    // 16-WO Coordination
    // 17-WO Logistics
    // 22-Washington Office Line Officer
    // 23-Acting Washington Office Line Officer
    const woOffice = this.data.user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17, 22, 23].includes(obj.value))
    this.is_WO = woOffice;

    // 3-BAER Regional Coordinator
    // 12-RO BAER Coordinator
    // 13-RO BAR Coordinator
    // 14-RO READ Coordinator
    // 15-RO Disaster Coordinator
    // 20-Regional Line Officer
    // 21-Acting Regional Line Officer
    const regionOffice = this.data.user.baer_roles.find((obj: any) => [3, 12, 13, 14, 15, 20, 21].includes(obj.value))

    this.userService.getPossibleLeadList().subscribe((data: any) => {
      let users = data.users
      this.sortFlattenAssessments(users);
      if (!woOffice) {
        if (!regionOffice) {
          //forest office or simple user
          users.forEach((user: any) => {
            if (this.fireRecord.region == user.region && this.fireRecord.forest_name == user.forest_name) this.leadList.push(user)
          })
        }
        else {
          //region office
          users.forEach((user: any) => {
            if (this.fireRecord.region == user.region) this.leadList.push(user)
          })
        }
        let lead = users.find((user: any) => user.user_id == this.fireRecord.team_lead_id)
        if (lead && !this.leadList.includes(lead)) {
          this.leadList.unshift(lead)
        }
      } //else wo office - filter nothing
      else {
        this.leadList = users;
        this.userList = users;

        //get lead's region to autofill form
        let lead = this.userList.find((user: any) => user.user_id == this.fireRecord.team_lead_id)
        if (lead) {
          this.fireForm.patchValue({
            region: lead.region,
          })
        }
      }
    });
  }

  sortFlattenAssessments(users: any) {
    const today = new Date();
    for (let user of users) {
      let assessments: any = [];
      assessments = [...assessments, ...user.assessments];
      for (let report of user.reports) {
        assessments = [...assessments, ...[report.assessment]];
      }
      assessments = assessments.filter((x: any) => (new Date(x.close_out_date)) > today);
      assessments.sort((a: any, b: any) => a.close_out_date > b.close_out_date);
      user.filteredAssessment = assessments[0];

      let tooltip = '';
      if (user.filteredAssessment) {
        tooltip = 'Fire Assignment:\nFire name: ' + user.filteredAssessment.baer_name
        user.filteredAssessment.start_date = (new Date(user.filteredAssessment.tentative_start_week)).toLocaleDateString("en-US")
        user.filteredAssessment.close_date = (new Date(user.filteredAssessment.close_out_date)).toLocaleDateString("en-US")
      }
      user.assessmentTooltip = tooltip;
    }
  }

  regionChanged(): void {
    if (this.fireForm.value.region && this.fireForm.value.region != "--") {
      this.leadList = this.userList.filter((user: any) => {
        return user.region == this.fireForm.value.region
      })
      if (!this.leadList.includes(this.fireForm.getRawValue().teamLeads)) {
        this.fireForm.patchValue({
          teamLeads: null
        })
      }
    } else {
      this.leadList = this.userList
    }
  }
}
