<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title>
            <mat-icon class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
            Full vs. Phased Fire Assessments</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <p>
            <b>
                Full Fire Assessment:
            </b>
        </p>
        <p>
            In a full fire assessment, data from all previous assessment will be brought forward, and can be modified as needed.
        </p>
        <img class="fire-image" src="\assets\img\full-fire.jpg"
            alt="Full Fire assessment example">
        <p>
            <b>
                Phased Assessment:
            </b>
        </p>
        <p>
            A phased assessment will cover a particular section or boundary of the fire. When creating an interim report, no data will be copied over from the previous assessment.
        </p>
        <img class="fire-image" src="\assets\img\phased-fire.jpg"
            alt="Phased Fire assessment example">
    </mat-dialog-content>
</div>